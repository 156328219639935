import clsx from 'clsx';
import { composeFileFolderPath, FileTypes, IMAGES_ABOUT_MUGSHOT_NAMES } from '../../constants/files';
import './Carousel.css';

type Props = {
  className?: string;
}

const Carousel = ({className}: Props) => {

  return (
    <div className={clsx(className, 'relative h-full w-full flex flex-row min-h-80 justify-center items-center')}>
      {
        IMAGES_ABOUT_MUGSHOT_NAMES.map((imgName, idx) => (
          <img
            key={idx}
            src={`${composeFileFolderPath(FileTypes.IMAGE_ABOUT)}${imgName}`}
            alt={`About image ${idx}`}
            className={clsx('carItem rounded-md absolute top-0 opacity-0 h-full object-cover')}
          />
        ))
      }
    </div>
  )
}

export default Carousel