import { useWindowSize } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import LogoSvg from '../../assets/tree-line.svg?react';
import { composeFileFolderPath, FileTypes, IMAGES_DIGITAL_LEAF_NAME, VIDEOS_LANDING_NAME_HD, VIDEOS_LANDING_NAME_LD, VIDEOS_LANDING_NAME_MD } from '../../constants/files';
import './Video.css';

interface Props {
  className?: string;
}

const Video = ({ className }: Props) => {
  const ref = useRef<HTMLVideoElement>(null)
  const [videoStarted, setVideoStarted] = useState(false)
  const [videoEnded, setVideoEnded] = useState(false)

  const size = useWindowSize();

  const videoStartedCallback = () => {
    setVideoStarted(true);
  }
  const videoEndedCallback = () => {
    setVideoStarted(false);
    setVideoEnded(true);
  }
  const videoWaitingCallback = () => {}

  const versionedVideoName = useMemo(() => {
    if (size.width) {
      // INFO - MD
      if (size.width > 769 && size.width < 1280) {
        return `${composeFileFolderPath(FileTypes.VIDEO_LANDING)}${VIDEOS_LANDING_NAME_MD}`;
      // INFO - HD
      } else if (size.width >= 1280) {
        return `${composeFileFolderPath(FileTypes.VIDEO_LANDING)}${VIDEOS_LANDING_NAME_HD}`;
      }
    }
    // INFO - LD
    return `${composeFileFolderPath(FileTypes.VIDEO_LANDING)}${VIDEOS_LANDING_NAME_LD}`;
  }, [size])

  useEffect(() => {
    if (ref.current) {
      ref.current?.addEventListener('play', videoStartedCallback)
      ref.current?.addEventListener('waiting', videoWaitingCallback)
      ref.current?.addEventListener('ended', videoEndedCallback)
    }

    return () => {
      if (ref.current) {
        ref.current?.removeEventListener('play', videoStartedCallback)
        ref.current?.removeEventListener('waiting', videoWaitingCallback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current?.removeEventListener('ended', videoEndedCallback)
      }
    }
  }, [ref])


  return (
    <div className={clsx('videoContainer flex justify-center items-center w-100 relative', className)}>
      <div className={clsx({'invisible': !videoEnded, 'relative': videoEnded, 'absolute top-0': !videoEnded}, "h-48 md:h-60 w-full flex justify-center items-center")}>
        <LogoSvg className='logoSvg' />
      </div>
      <video
        className={clsx({'invisible': !videoStarted, 'hidden': videoEnded}, 'object-cover w-full')}
        ref={ref}
        muted={true}
        autoPlay={true}
        controls={false}
      >
        <source src={versionedVideoName} type="video/mp4" />
        <img src={`${composeFileFolderPath(FileTypes.IMAGE_COMMON)}${IMAGES_DIGITAL_LEAF_NAME}`} alt="Digital Leaf" />
      </video>

    </div>
  )
}

export default Video