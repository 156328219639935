import { environmentVariables } from './environment';

export enum FileTypes {
  IMAGE_TOOL_ITEM,
  IMAGE_TOOL_GROUP,
  IMAGE_SKILL_ITEM,
  IMAGE_SKILL_GROUP,
  IMAGE_PROJECT,
  IMAGE_SITE_LIB,
  IMAGE_ABOUT,
  IMAGE_COMMON,
  VIDEO_LANDING,
}

const images_folder = 'images';
const videos_folder = 'videos';
const images_generic_item = 'item';
const images_generic_group = 'group';
const images_tools_folder = 'tools';
const images_projects_folder = 'projects';
const images_skills_folder = 'skills';
const images_site_libs_folder = 'site_items';
const images_common_folder = 'common';
const images_about_folder = 'about';

export const IMAGES_DIGITAL_LEAF_NAME = 'tree-solid-blue_png.png'
export const VIDEOS_LANDING_NAME_LD = 'bf_home_ld.mp4';
export const VIDEOS_LANDING_NAME_MD = 'bf_home_md.mp4';
export const VIDEOS_LANDING_NAME_HD = 'bf_home_hd.mp4';
export const IMAGE_SITE_ITEMS_BG_NAME = 'flasks.jpg';
export const IMAGE_HOME_BG_NAME = 'luxury_living_room.jpg';
export const IMAGE_NOT_ALLOWED_NAME = 'no_entry_sign.jpg';
export const IMAGES_ABOUT_MUGSHOT_NAMES: string[] = [
  'a9.jpg',
  'c70.jpg',
  'b73.jpg',
  'e10.jpg',
  'f87.jpg',
  'h90.jpg',
]

export const composeFileFolderPath: (fileType: FileTypes) => string = (fileType) => {
  const baseBucketPath: string = environmentVariables.VITE_FILE_BUCKET_PATH;

  if ([FileTypes.VIDEO_LANDING].includes(fileType)) {
    return `${baseBucketPath}${videos_folder}/`;
  }

  if (![FileTypes.VIDEO_LANDING].includes(fileType)) {
    const baseImagesBucketPath: string = `${baseBucketPath}${images_folder}/`;

    switch (fileType) {
      case FileTypes.IMAGE_TOOL_ITEM:
        return `${baseImagesBucketPath}${images_tools_folder}/${images_generic_item}/`;
      case FileTypes.IMAGE_TOOL_GROUP:
        return `${baseImagesBucketPath}${images_tools_folder}/${images_generic_group}/`;
      case FileTypes.IMAGE_SKILL_ITEM:
        return `${baseImagesBucketPath}${images_skills_folder}/${images_generic_item}/`;
      case FileTypes.IMAGE_SKILL_GROUP:
        return `${baseImagesBucketPath}${images_skills_folder}/${images_generic_group}/`;
      case FileTypes.IMAGE_PROJECT:
        return `${baseImagesBucketPath}${images_projects_folder}/`;
      case FileTypes.IMAGE_ABOUT:
        return `${baseImagesBucketPath}${images_about_folder}/`;
      case FileTypes.IMAGE_COMMON:
        return `${baseImagesBucketPath}${images_common_folder}/`;
      case FileTypes.IMAGE_SITE_LIB:
        return `${baseImagesBucketPath}${images_site_libs_folder}/`;
      default:
        break;
    }
  }

  throw new Error('Invalid file type');
}
